// find . -type f -name "*.tsx" -print0 | xargs -0 sed -i '' -e 's/withTheme/withMetrics/g'
import * as React from 'react'
import { ThemeProvider as ThemeProvider_, ThemeIF, theme } from './ThemeContext'
// import themeObjectFromYaml from '@src/theme/theme.yaml'
// import theme from '@src/design/theme'

export interface ThemeProviderProps {}

export interface ThemeProviderState {
  theme: ThemeIF
}

export class ThemeProvider extends React.Component<
  ThemeProviderProps,
  ThemeProviderState
> {
  static defaultProps = {}

  constructor(props: ThemeProviderProps) {
    super(props)

    this.state = { theme }
  }
  render() {
    // console.log(this.state)
    return (
      <ThemeProvider_ value={this.state.theme}>
        {this.props.children}
      </ThemeProvider_>
    )
  }
}
