export const themeColor = '#FC8D01'

export const colors = {
  white: '#FFFFFF',
  transparentFF: 'rgba(255,255,255,0)',
  transparent00: 'rgba(0,0,0,0)',
  green: 'rgb(69, 224, 79)',
  green50: 'rgba(69,224,79, 0.5)',
  darkGreen: '#18291A',
  deepGreen: '#3E7344',
  deepGreen50: 'rgba(62,115,68,0.5)',
  formPlaceholderTextColor: '#BCDCC0',
  mapPin: '#009D46',
  gray: '#E3E3E3',
  lightGray: '#F6F6F6',
  ultraLightGray: '#FAFAFA',
  background: '#FAFAFA',
  theme: themeColor,
  orange: themeColor,
  highOrange: '#FC6D01',
  black: '#000000',
  black80: '#808080',
  black40: '#404040',
  blackAE: '#AEAEAE',
}
