export const pages = [
  {
    label: 'Home',
    path: '/',
    title: 'Cacicolle - Find your taste!',
    // description: '',
    keywords:
      'Cacicolle,Cacicolle App,Cacicolle ios,Cacicolle Android,Shopping List,Product,Snack',
  },
  {
    label: 'Brand guidelines',
    path: '/brand-guidelines/',
    title: 'Brand guidelines - Cacicolle',
    // description: '',
    keywords:
      'Brand guidelines,Cacicolle,Cacicolle App,Shopping List,Product,Snack',
  },
  {
    label: 'Contact',
    path: '/contact/',
    title: 'Contact us - Cacicolle',
  },
]
