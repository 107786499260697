export const pages = [
  {
    label: 'ホーム',
    path: '/ja/',
    title: 'Cacicolle - 好みの味を見つけよう!',
    // description: '',
    keywords:
      'カシコレ,かしこれ,Cacicolle,カシコレアプリ,Cacicolle アンドロイド,Cacicolle App,Cacicolle ios,Cacicolle Android,Shopping List,Product,Snack',
  },
  {
    label: 'ロゴガイドライン',
    path: '/ja/brand-guidelines/',
    title: 'Brand guidelines - Cacicolle',
    description: 'Guidelines for the Cacicolle logo.',
    keywords:
      'ブランドガイドライン,ロゴガイドライン,ロゴ,Cacicolle logo,カシコレロゴ,Cacicolle App,Cacicolle ios,Cacicolle Android',
  },
  {
    path: '/ja/contact/',
    title: 'お問い合わせ - Cacicolle',
    label: 'お問い合わせ',
  },
]
