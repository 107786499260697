import * as React from 'react'
import { ThemeConsumer, ThemeContextIF } from './ThemeContext'

export interface withThemeProps {
  theme: ThemeContextIF
}

export function withTheme<P extends {}>(
  Component: React.ComponentType<P & withThemeProps>,
): React.ComponentType<P> {
  const WrappedComponent: any = ({ forwardedRef, ...props }: any) => (
    <ThemeConsumer>
      {(theme) => <Component ref={forwardedRef} {...props} theme={theme} />}
    </ThemeConsumer>
  )
  return React.forwardRef((props, ref) => (
    <WrappedComponent {...props} forwardedRef={ref} />
  )) as any
}
