import { description, defaultTitle, keywords } from './siteMeta'
import { colors } from '@src/theme/colors'
import { pages } from '@config/pages'

export const downloadUrls = {
  android: undefined, // 'https://play.google.com/store/apps/details?id=com.podcast_plus1',
  ios: undefined, // 'https://itunes.apple.com/us/app/podcast-1/id1358094409?ls=1&mt=8',
}

export const name = 'Cacicolle'
const titleTemplate = name
// envrinment
export const GoogleAnalytics = {
  trackingId: 'UA-126651957-1',
}
export const GoogleMap = {
  placeId: 'ChIJSXcgydyMGGAR63elql-vXhs',
  API_KEY:
    process.env.NODE_ENV === 'development'
      ? ''
      : 'AIzaSyC9IauuEKNSnhN-kPBRCBpWZ4i2DKQBsQc',
  zoom: 14, // 4 for international, 14 for internal
}
export const SLACK_WEBHOOK_URL =
  'https://hooks.slack.com/services/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'
export const DEFAULT_EMAIL = 'noreply@plusuno.jp'
export const ADMIN_EMAIL = 'k.o1@plusuno.jp'
export const DOMAIN = 'cacicolle.com'
export const SENDGRID_API_KEY =
  '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'

// SEO
export const siteTitle = {
  ja: {
    default: defaultTitle,
    template: titleTemplate,
    templateSeparator: ' | ', // ["|", "-"]
    templatePosition: 'end', // ['start', 'end']
  },
  en: {
    default: defaultTitle,
    template: titleTemplate,
    templateSeparator: ' | ', // ["|", "-"]
    templatePosition: 'end', // ['start', 'end']
  },
}
export const twitter = ''
export const facebookAppId = ''
export const siteUrl = `https://www.${DOMAIN}`
export const sitemap = `${siteUrl}/sitemap.xml`
export const defaultLocale = 'ja'

interface localesPath {
  ja: string
  en: string
  [key: string]: string
}
export type localeType = 'ja' | 'en'
export const locales: localesPath = {
  en: '/en',
  ja: '',
}

export const companyName = 'Plusuno Inc.'

const author = companyName

const site = {
  title: siteTitle,
  keywords,
  description,
  url: siteUrl,
  sitemap,
  manifest: {
    name,
    short_name: name,
    description,
    start_url: '/',
    background_color: colors.background,
    theme_color: colors.theme,
    display: 'minimal-ui',
    orientation: 'portrait',
  },
}

export { pages, author, site }
