import React from 'react'
import { graphql } from 'gatsby'
import { Copyright } from '@src/components/Copyright'
import {
  GraphqlPolicyPageListFragmentResult,
  formatPolicyPageListProps,
} from '@src/components/PolicyPageLinkList'
import { FooterNavList, FooterNavListItem } from './FooterNavList'
import './footer.sass'

export interface GraphqlResultSitePage {
  path: string
  context: {
    title: string
  }
}
export interface FooterProps extends GraphqlPolicyPageListFragmentResult {
  contact: GraphqlResultSitePage
  brandGuidelines: GraphqlResultSitePage
}
function extractGraphqlResultSitePageToLink({
  path,
  context: { title },
}: GraphqlResultSitePage): { title: string; path: string } {
  return { path, title }
}

export default class Footer extends React.PureComponent<FooterProps> {
  state = {
    ssr: 'false',
  }
  componentDidMount() {
    this.setState({ ssr: 'true' })
  }
  render() {
    const { policies, contact, brandGuidelines } = this.props

    if (!policies) return null
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="footer-inner-box">
            {/* <PolicyPageLinkList policies={policies} /> */}
            <div style={{ order: 2, flex: 7 }} className="footer-links">
              <FooterNavList>
                {formatPolicyPageListProps(policies)
                  .concat(extractGraphqlResultSitePageToLink(brandGuidelines))
                  .concat(extractGraphqlResultSitePageToLink(contact))
                  .map((nav) => (
                    <FooterNavListItem {...nav} key={nav.path} />
                  ))}
              </FooterNavList>
            </div>
            <div className="footer-copyright">
              <Copyright />
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export const query = graphql`
  fragment FooterFragment on Query {
    ...PolicyPageListFragment
    contact: sitePage(
      path: { regex: "//contact/$/" }
      context: { language: { eq: $language } }
    ) {
      path
      context {
        title: label
      }
    }
    brandGuidelines: sitePage(
      path: { regex: "//brand-guidelines/$/" }
      context: { language: { eq: $language } }
    ) {
      path
      context {
        title: label
      }
    }
  }
`
