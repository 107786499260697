export const mediaSizes = {
  phoneUp: 600,
  tabletPortraitUp: 900,
  tabletLandscapeUp: 1200,
  desktopUp: 1800,
}
export const mediaQuery = {
  forPhoneOnly: '@media (max-width: 599px)',
  forMobile: '@media (max-width: 899.9px)',
  forTabletPortraitUp: '@media (min-width: 600px)',
  forTabletLandscapeUp: '@media (min-width: 900px)',
  forDesktopUp: '@media (min-width: 1200px)',
  forBigDesktopUp: '@media (min-width: 1800px)',
}
