import * as React from 'react'
import { Dimensions, ScaledSize } from 'react-native'
import { MetricsProvider as MetricsProvider_ } from './MetricsContext'
import { mediaSizes } from './metrics'
const { width } = Dimensions.get('window')

export interface MetricsProviderProps {}

export interface MetricsProviderState {
  // width: number
  // height: number
  windowSizeKey: number
}

export class MetricsProvider extends React.Component<
  MetricsProviderProps,
  MetricsProviderState
> {
  static defaultProps = {}

  constructor(props: MetricsProviderProps) {
    super(props)

    if (width < mediaSizes.phoneUp) {
      this.currentWindowWidthKey = 0
    } else if (width < mediaSizes.tabletPortraitUp) {
      this.currentWindowWidthKey = 1
    } else if (width < mediaSizes.tabletLandscapeUp) {
      this.currentWindowWidthKey = 2
    } else if (width < mediaSizes.desktopUp) {
      this.currentWindowWidthKey = 3
    } else {
      this.currentWindowWidthKey = 4
    }

    this.state = {
      windowSizeKey: this.currentWindowWidthKey,
    }
  }
  private _timeout?: number
  currentWindowWidthKey: number

  _dimensionsChangeHandler = async ({
    window: { width },
  }: {
    window: ScaledSize
    screen: ScaledSize
  }) => {
    // If there's a timer, cancel it
    if (this._timeout) {
      window.cancelAnimationFrame(this._timeout)
    }

    // Setup the new requestAnimationFrame()
    this._timeout = window.requestAnimationFrame(() => {
      console.log({ width })
      let changed = false

      if (width < mediaSizes.phoneUp) {
        if (this.currentWindowWidthKey !== 0) {
          this.currentWindowWidthKey = 0
          changed = true
        }
      } else if (width < mediaSizes.tabletPortraitUp) {
        if (this.currentWindowWidthKey !== 1) {
          this.currentWindowWidthKey = 1
          changed = true
        }
      } else if (width < mediaSizes.tabletLandscapeUp) {
        if (this.currentWindowWidthKey !== 2) {
          this.currentWindowWidthKey = 2
          changed = true
        }
      } else if (width < mediaSizes.desktopUp) {
        if (this.currentWindowWidthKey !== 3) {
          this.currentWindowWidthKey = 3
          changed = true
        }
      } else if (width > mediaSizes.desktopUp) {
        if (this.currentWindowWidthKey !== 4) {
          this.currentWindowWidthKey = 4
          changed = true
        }
      }

      if (changed) {
        this.setState({ windowSizeKey: this.currentWindowWidthKey })
      }
    })
  }
  componentDidMount() {
    Dimensions.addEventListener('change', this._dimensionsChangeHandler)
  }
  componentWillUnmount() {
    Dimensions.removeEventListener('change', this._dimensionsChangeHandler)
  }
  render() {
    // console.log(this.state)
    return (
      <MetricsProvider_ value={this.state}>
        {this.props.children}
      </MetricsProvider_>
    )
  }
}
