import * as React from 'react'
import { colors } from '@src/theme/colors'

interface CopyrightProps {}

export const Copyright: React.SFC<CopyrightProps> = () => (
  <React.Fragment>
    <div className="copyright">
      <div
        style={{
          color: colors.black80,
          fontWeight: 300,
          lineHeight: '50px',
          fontSize: 12,
          paddingRight: '3.12%',
          paddingLeft: '3.12%',
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        © 2018 Plusuno Inc.
      </div>
    </div>
  </React.Fragment>
)
