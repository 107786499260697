// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-page-template-tsx": () => import("/opt/build/repo/src/templates/LegalPageTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brand-guidelines-tsx": () => import("/opt/build/repo/src/pages/brand-guidelines.tsx" /* webpackChunkName: "component---src-pages-brand-guidelines-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-site-policy-tsx": () => import("/opt/build/repo/src/pages/site-policy.tsx" /* webpackChunkName: "component---src-pages-site-policy-tsx" */),
  "component---src-pages-thanks-tsx": () => import("/opt/build/repo/src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

