// # OPTIONS

// # SPACE
export const space = {
  // # space options
  default: '20px',
  xxs: '2px',
  xs: '5px',
  s: '10px',
  m: '20px',
  l: '40px',
  xl: '80px',
  inset: {
    default: '20px',
    xs: '5px',
    s: '10px',
    m: '20px',
    l: '40px',
  },
  stack: {
    default: '0 0 20px 0',
    xxs: '0 0 2px 0',
    xs: '0 0 5px 0',
    s: '0 0 10px 0',
    m: '0 0 20px 0',
    l: '0 0 40px 0',
  },
  inline: {
    default: '0 20px 0 0',
    xxs: '0 2px 0 0',
    xs: '0 5px 0 0',
    s: '0 10px 0 0',
    m: '0 20px 0 0',
    l: '0 40px 0 0',
  },
}
// # SIZE
export const size = {
  radius: {
    default: '10px',
    small: '5px',
  },
}
// # COLOR
const white = '#ffffff'
const color_gray_1 = '#fafaf9'
const color_gray_2 = '#f3f2f2'
const color_gray_3 = '#ecebea'
const color_gray_10 = '#3e3e3c'
const color_brand_primary = '#FC8D01'
const color_special_attention = 'orange'
export const color = {
  // # color options
  neutral: {
    white,
    gray: {
      a: color_gray_1,
      b: color_gray_2,
      c: color_gray_3,
      d: '#dddbda',
      e: '#c9c7c5',
      f: '#b0adab',
      g: '#969492',
      h: '#706e6b',
      i: '#514f4d',
      j: color_gray_10,
      k: '#2b2826',
    },
    black: '#000000',
  },
  brand: {
    primary: color_brand_primary,
    primaryActive: '#709425',
    light: '#709425',
    lightActive: '#709425',
    dark: '#709425',
    darkActive: '#709425',
  },
  special: {
    attention: color_special_attention,
  },
}
// # FONT
const font_family_target = "'Open Sans'"
const font_size_xxs = '.8em'
const font_size_xs = '.95em'
const font_size_s = '1.1em'
const font_size_m = '1.35em'
const font_size_l = '1.7em'
const font_size_xl = '2em'
const font_size_xxl = '2.2em'
const font_size_xxxl = '2.8em'
const font_line_height_xs = '1.1'
const font_line_height_s = '1.2'
const font_line_height_m = '1.3'
const font_line_height_l = '1.4'
const font_line_height_xl = '1.5'
const font_line_height_xxl = '1.6'
const font_line_height_reset = '1'

export const font = {
  // # font options
  family: {
    initial: 'Arial, sans-serif',
    target: font_family_target,
  },
  weight: {
    standard: 400,
    bold: 600,
  },
  size: {
    default: '1rem',
    xxs: font_size_xxs,
    xs: font_size_xs,
    s: font_size_s,
    m: font_size_m,
    l: font_size_l,
    xl: font_size_xl,
    xxl: font_size_xxl,
    xxxl: font_size_xxxl,
  },
  lineHeight: {
    xs: font_line_height_xs,
    s: font_line_height_s,
    m: font_line_height_m,
    l: font_line_height_l,
    xl: font_line_height_xl,
    xxl: font_line_height_xxl,
    reset: font_line_height_reset,
  },
}
// # TIME
export const time = {
  duration: {
    default: '0.5s',
    long: '1s',
  },
  duration_millisecond: {
    default: 500,
    long: 1000,
  },
}
// # GENERAL TOKENS
export const background = {
  color: {
    primary: white,
    alt: color_gray_1,
    brand: color_brand_primary,
  },
}
export const text = {
  family: font_family_target,
  color: {
    primary: color_gray_10,
    primaryInverse: white,
    brand: color_brand_primary,
    attention: color_special_attention,
  },
  lineHeight: {
    default: font_line_height_l,
  },
  maxWidth: {
    tablet: '650px',
    desktop: '700px',
  },
}
export const heading = {
  family: font_family_target,
  size: {
    h1: font_size_xl,
    h2: font_size_l,
    h3: font_size_m,
  },
  lineHeight: {
    h1: font_line_height_xs,
    h2: font_line_height_xs,
    h3: font_line_height_xs,
  },
  weight: 600,
}
export const line = {
  color: color_gray_3,
}
export const icon = {
  color: color_brand_primary,
}
// // # COMPONENT TOKENS
// export const hero =
//   h1:
//     size: font_size_xxl
//     color: color_white
//     lineHeight: font_line_height_xs
//   background: 'linear-gradient(0deg, #E0306E, #6438B5)'
// blog:
//   h1:
//     size: font-size-l
//     lineHeight: font-line-height-xs
//     hoverColor: color-brand-primary
// header:
//   height:
//     default: 80px
//     fixed: 50px;
//     homepage: 100px;
export const theme = {
  color,
  font,
  line,
  size,
  space,
  text,
  time,
  background,
}
