import React from 'react'
import { graphql, Link } from 'gatsby'

export function formatPolicyPageListProps(
  policies: GraphqlPolicyPageListFragmentResult['policies'],
): PolicyPageLinkListProps['policies'] {
  return policies.edges.map(
    ({
      node: {
        frontmatter: { title },
        fields: { slug: path },
      },
    }) => ({ title, path }),
  )
}
export interface GraphqlPolicyPageListFragmentResult {
  policies: {
    edges: {
      node: PolicyPageItemProps
    }[]
  }
}
export interface PolicyPageItemProps {
  id: string
  fields: {
    slug: url
  }
  frontmatter: {
    title: string
  }
  // path: string
  // context: {
  //   basePath: string
  //   title: string
  //   description: string
  //   keywords: string
  //   language: string
  // }
}
export interface PolicyPageLinkProps {
  path: string
  title: string
}
export interface PolicyPageLinkListProps {
  policies: PolicyPageLinkProps[]
}

const PolicyPageLink: React.SFC<PolicyPageLinkProps> = ({ path, title }) => (
  <li>
    <Link to={path}>{title}</Link>
  </li>
)

export const PolicyPageLinkList: React.SFC<
  GraphqlPolicyPageListFragmentResult
> = ({ policies }) => (
  <ul>
    {formatPolicyPageListProps(policies).map(({ title, path }) => (
      <PolicyPageLink key={path} path={path} title={title} />
    ))}
  </ul>
)

export const query = graphql`
  fragment PolicyPageListFragment on Query {
    policies: allMarkdownRemark(
      filter: {
        frontmatter: { language: { eq: $language }, skip: { ne: true } }
      }
      sort: { fields: [frontmatter___title] }
    ) {
      # totalCount
      edges {
        node {
          # id
          # html
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
