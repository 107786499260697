import React from 'react'
import { SEO } from '@src/components/SEO'
import Header from '@src/components/Header'
import Footer, { FooterProps } from '@src/components/Footer'
import { ThemeProvider } from '@src/design/ThemeProvider'
import { I18nProvider } from '@lingui/react'
import { MetricsProvider } from '@src/design/MetricsProvider'
import { langFromPath, availableLanguageNames } from '@src/i18n'
import { catalogs } from '@src/i18n/catalogs'
import { navigate } from 'gatsby'

import '@src/theme/style.sass'
import { CookieNotice } from '@src/components/organisms/CookieNotice'
import { colors } from '@src/theme/colors'
import { Storage, COOKIE } from '@src/utils/store/storage'

interface GraphqlResult extends FooterProps {}
interface Location {
  // ancestorOrigins: DOMStringList {length: 0}
  assign: anyfunc
  hash: string // ""
  host: string // "localhost:8000"
  hostname: string // "localhost"
  href: string // "http://localhost:8000/jp/brand-guidelines/"
  key: string // "initial"
  origin: string // "http://localhost:8000"
  pathname: string // "/jp/brand-guidelines/"
  port: string // "8000"
  protocol: string // "http:"
  reload: anyfunc // ƒ reload()
  replace: anyfunc
  search: string // ""
  state: null
  toString: anyfunc
}
interface PageContext {
  language: availableLanguageNames // 'ja' | 'en'
  basePath: string
  title: string
  description: string
  keywords: string
  path: string
}

interface GatsbyRootProps {
  '*': string // 'jp/brand-guidelines'
  data: GraphqlResult
  isMain: true
  location: Location
  navigate: typeof navigate
  pageContext: PageContext
  pageResources: anyfunc
  path: string // "/*"
  pathContext: PageContext
  uri: string // "/"
}
interface LayoutProps extends GatsbyRootProps {}
interface LayoutState {
  isCookieNoticeShowed: boolean
}

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props)

    this.state = {
      isCookieNoticeShowed: true,
    }
  }
  componentDidMount() {
    this.cookieShowed()

    if (!document.getElementById('webfontloader')) {
      ;(function(d) {
        const wf = d.createElement('script'),
          s = d.scripts[0]
        wf.src =
          'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'
        wf.async = true
        wf.onload = () => {
          WebFont.load({
            google: {
              families: ['Comfortaa:300,400,500,700'],
              // families: ['Mplus 1p:400,500,700,800'],
            },
          })
        }

        s.parentNode && s.parentNode.insertBefore(wf, s)
      })(document)
    }
  }

  async cookieShowed() {
    try {
      const isCookieNoticeShowed = await Storage.get('cookie_showed')
      this.setState({
        isCookieNoticeShowed: isCookieNoticeShowed === COOKIE.SHOWED,
      })
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    const { pageContext, data, location, children } = this.props
    const { isCookieNoticeShowed } = this.state
    const { language } = pageContext
    console.log({ props: this.props })
    console.log({ language })
    console.log({ lang: langFromPath(location.pathname) })
    return (
      <I18nProvider
        language={language || langFromPath(location.pathname)}
        catalogs={catalogs}
      >
        <MetricsProvider>
          <ThemeProvider>
            <React.Fragment>
              <SEO {...pageContext} />
              <div
                style={{
                  minHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Header />
                <main
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  {children}
                </main>
                {!isCookieNoticeShowed && <CookieNotice />}
                <Footer {...data} />
              </div>
              <style jsx global>{`
                html {
                  box-sizing: border-box;
                }
                ::selection {
                  color: ${colors.white};
                  background-color: ${colors.theme};
                }
                *,
                *:after,
                *:before {
                  box-sizing: inherit;
                  margin: 0;
                  padding: 0;
                }
                h1,
                h2,
                h3 {
                  line-height: 1.1;
                  letter-spacing: -0.03em;
                  margin: 0;
                }
                h1 {
                  letter-spacing: -0.04em;
                }
                p {
                  margin: 0;
                }
                strong {
                }
                a {
                  text-decoration: none;
                  color: #666;
                }
                main {
                  width: auto;
                  display: block;
                }
              `}</style>
            </React.Fragment>
          </ThemeProvider>
        </MetricsProvider>
      </I18nProvider>
    )
  }
}
export { Layout }
