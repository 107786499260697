// https://www.netlify.com/blog/2017/01/19/setting-cookies-in-react/
// https://www.robinwieruch.de/local-storage-react/
// https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie

import Cookies from 'universal-cookie'
const cookies = new Cookies()

type SecureStorageKeys = 'email' | 'password'

export const SecureStorage = {
  set: async (key: SecureStorageKeys, value: string) => {
    cookies.set(key, value)
  },
  get: async (key: SecureStorageKeys): Promise<string | null> => {
    return await cookies.get(key)
  },
  delete: async (key: SecureStorageKeys) => {
    cookies.remove(key)
  },
}

type StorageKeys = 'language' | 'cookie_showed'

export const COOKIE = { SHOWED: 'y' }
export const Storage = {
  set: async (key: StorageKeys, value: string) => {
    localStorage.setItem(key, value)
  },
  get: async (key: StorageKeys): Promise<string | null> => {
    return await localStorage.getItem(key)
  },
  delete: async (key: StorageKeys) => {
    localStorage.removeItem(key)
  },
}
