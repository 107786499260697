import React from 'react'
import { Text, Animated, TouchableOpacity } from 'react-native'
import { colors } from '@src/theme/colors'
import { Link } from 'gatsby'
import { Trans } from '@lingui/react'
import { Storage, COOKIE } from '@src/utils/store/storage'
import { withTheme, withThemeProps } from '@src/design/withTheme'

interface CookieNoticeProps extends withThemeProps {
  textColor: string
}

@(withTheme as any)
export class CookieNotice extends React.PureComponent<CookieNoticeProps> {
  static defaultProps = {
    textColor: colors.white,

    // to avoid error from decorator
    // TODO: fix type definition for decorators
    theme: undefined,
  }
  state = {
    accepted: false,
    opacity: new Animated.Value(0),
    whileHiding: true,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ whileHiding: false })
    }, 0)
  }

  handlePress = () => {
    Storage.set('cookie_showed', COOKIE.SHOWED)

    this.setState({ whileHiding: true })

    setTimeout(() => {
      this.setState({ accepted: true })
    }, this.props.theme.time.duration_millisecond.long)
  }

  render() {
    if (this.state.accepted) return null

    const { theme } = this.props
    const { whileHiding } = this.state

    return (
      <React.Fragment>
        <div
          className={`cookie-notice ${whileHiding && 'hide'}`}
          style={{
            ...(whileHiding && { pointerEvents: 'none' }),
            width: '100%',
            // height: 70,
            backgroundColor: colors.orange,
            // opacity,
            paddingTop: 20,
            paddingBottom: 20,
            paddingRight: 16,
            paddingLeft: 16,
            flexDirection: 'row',
            position: 'fixed',
            bottom: 0,
          }}
        >
          <div
            style={{
              flex: 7,
              justifyContent: 'center',
              marginBottom: 10,
            }}
          >
            <p
              style={{
                color: this.props.textColor,
                textAlign: 'center',
                display: 'block',
                fontSize: 14,
              }}
            >
              <Trans>
                We love cookies 🍪. Cookies help us deliver our services (
                <Link
                  to="/privacy"
                  style={{
                    color: this.props.textColor,
                    textDecorationLine: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  Learn more
                </Link>
                ). By using our services, you agree to our use of cookies.
              </Trans>
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TouchableOpacity
              style={{
                borderWidth: 3,
                borderColor: colors.white,
                borderRadius: 40,
                paddingVertical: 7,
                paddingHorizontal: 20,
              }}
              onPress={this.handlePress}
            >
              <Text style={{ color: colors.white }}>
                <Trans>Got it!</Trans>
              </Text>
            </TouchableOpacity>
          </div>
        </div>

        <style jsx>{`
          .cookie-notice {
            transition: opacity ${theme.time.duration.long};
            opacity: 1;
          }
          .cookie-notice.hide {
            opacity: 0;
          }
        `}</style>
      </React.Fragment>
    )
  }
}
