import compose from 'lodash/fp/compose'
import pick from 'lodash/fp/pick'
import flatten from 'lodash/fp/flatten'
import values from 'lodash/fp/values'
import compact from 'lodash/fp/compact'

export const languages: string[] = ['en', 'ja']
export const languagePathPrefixMap = {
  en: '',
  ja: '/ja',
}
export const defaultLanguage = 'en'

export type availableLanguageNames = keyof typeof languagePathPrefixMap
export function getLocalizedPath(
  lang: availableLanguageNames,
  path: string,
): string {
  return `${languagePathPrefixMap[lang]}${path}`
}
export const langPrefix = (lang: keyof typeof languagePathPrefixMap) =>
  languagePathPrefixMap[lang]
export const deprefix = (pathname: string) =>
  pathname.startsWith('/de/') ? pathname.substr(4) : pathname
export const langFromPath = (pathname: string) => {
  const maybeLangCode: string = pathname.split('/', 2)[1]
  return languages.includes(maybeLangCode) ? maybeLangCode : defaultLanguage
}

export const browserLanguagePropertyKeys = [
  'languages',
  'language',
  'browserLanguage',
  'userLanguage',
  'systemLanguage',
]
export const getLocale = () => {
  const d: any = compose(
    compact as any,
    flatten as any,
    values,
    pick(browserLanguagePropertyKeys),
  )(window.navigator)

  return (
    d
      .map((s: string) => s.substr(0, 2))
      .find((s: string) => languages.includes(s)) || 'en'
  )
}
