import * as React from 'react'
import { Link } from 'gatsby'

interface FooterNavListProps {}
export const FooterNavList: React.SFC<FooterNavListProps> = ({ children }) => (
  <React.Fragment>
    <div className="FooterNavListContainer">
      <ul className="UL">{children}</ul>
    </div>
  </React.Fragment>
)

interface FooterNavListItemProps {
  path: string
  title: string
}
export const FooterNavListItem: React.SFC<FooterNavListItemProps> = ({
  title,
  path,
}) => (
  <React.Fragment>
    <li>
      <Link className="footer-nav-link" to={path}>
        {title}
      </Link>
    </li>
  </React.Fragment>
)
