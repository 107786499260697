import * as React from 'react'
import { theme } from '@src/design/theme'

export interface ThemeContextIF extends ThemeIF {}
export const {
  Provider: ThemeProvider,
  Consumer: ThemeConsumer,
} = React.createContext({} as ThemeContextIF)

export type ThemeIF = typeof theme
export { theme }
// export interface ThemeIF extends  {
//   space: {
//     default: string // 20px
//     xxs: string // 2px
//     xs: string // 5px
//     s: string // 10px
//     m: string // 20px
//     l: string // 40px
//     xl: string // 80px
//     inset: {
//       default: string // 20px
//       xs: string // 5px
//       s: string // 10px
//       m: string // 20px
//       l: string // 40px
//     }
//     stack: {
//       default: string // 0 0 20px 0
//       xxs: string // 0 0 2px 0
//       xs: string // 0 0 5px 0
//       s: string // 0 0 10px 0
//       m: string // 0 0 20px 0
//       l: string // 0 0 40px 0
//     }
//     inline: {
//       default: string // 0 20px 0 0
//       xxs: string // 0 2px 0 0
//       xs: string // 0 5px 0 0
//       s: string // 0 10px 0 0
//       m: string // 0 20px 0 0
//       l: string // 0 40px 0 0
//     }
//   }
//   // # SIZE
//   size: {
//     radius: {
//       default: string // 10px
//       small: string // 5px
//     }
//   }
//   // # COLOR
//   color: {
//     // # color options
//     neutral: {
//       white: string // &color-white '#ffffff'
//       gray: {
//         a: string // &color-gray-1 '#fafaf9'
//         b: string // &color-gray-2 '#f3f2f2'
//         c: string // &color-gray-3 '#ecebea'
//         d: string // '#dddbda'
//         e: string // '#c9c7c5'
//         f: string // '#b0adab'
//         g: string // '#969492'
//         h: string // '#706e6b'
//         i: string // '#514f4d'
//         j: string // &color-gray-10 '#3e3e3c'
//         k: string // '#2b2826'
//       }
//       black: string //'#000000'
//     }
//     brand: {
//       primary: string // &color-brand-primary '#709425'
//       primaryActive: string // '#709425'
//       light: string // '#709425'
//       lightActive: string // '#709425'
//       dark: string // '#709425'
//       darkActive: string // '#709425'
//     }
//     special: {
//       attention: string // &color-special-attention orange
//     }
//   }
//   // # FONT
//   // font:
//   //   # font options
//   //   family:
//   //     initial: 'Arial, sans-serif'
//   //     target: &font-family-target 'Open Sans'
//   //   weight:
//   //     standard: 400
//   //     bold: 600
//   //   size:
//   //     xxs: &font-size-xs .8em
//   //     xs: &font-size-xs .95em
//   //     s: &font-size-s 1.1em
//   //     m: &font-size-m 1.35em
//   //     l: &font-size-l 1.7em
//   //     xl: &font-size-xl 2em
//   //     xxl: &font-size-xl 2.2em
//   //     xxxl: &font-size-xxl 2.8em
//   //   lineHeight:
//   //     xs: &font-line-height-xs 1.1
//   //     s: &font-line-height-s 1.2
//   //     m: &font-line-height-m 1.3
//   //     l: &font-line-height-l 1.4
//   //     xl: &font-line-height-l 1.5
//   //     xxl: &font-line-height-l 1.6
//   //     reset: &font-line-height-reset 1
//   // #TIME
//   // time:
//   //   duration:
//   //     default: 0.5s
//   //     long: 1s

//   // # GENERAL TOKENS
//   // background:
//   //   color:
//   //     primary: *color-white
//   //     alt: *color-gray-1
//   //     brand: *color-brand-primary
//   text: {
//     family: string // *font-family-target
//     color: {
//       primary: string //  *color-gray-10
//       primaryInverse: string //  *color-white
//       brand: string //  *color-brand-primary
//       attention: string //  *color-special-attention
//     }
//     lineHeight: {
//       default: string // *font-line-height-l
//     }
//     maxWidth: {
//       tablet: string // 650px
//       desktop: string //  700px
//     }
//   }
//   // heading:
//   //   family: *font-family-target
//   //   size:
//   //     h1: *font-size-xl
//   //     h2: *font-size-l
//   //     h3: *font-size-m
//   //   lineHeight:
//   //     h1: *font-line-height-xs
//   //     h2: *font-line-height-xs
//   //     h3: *font-line-height-xs
//   //   weight: 600
//   // line:
//   //   color: *color-gray-3
//   // icon:
//   //   color: *color-brand-primary

//   // hero:
//   //   h1:
//   //     size: *font-size-xxl
//   //     color: *color-white
//   //     lineHeight: *font-line-height-xs
//   //   background: 'linear-gradient(0deg, #E0306E, #6438B5)'
//   // blog:
//   //   h1:
//   //     size: *font-size-l
//   //     lineHeight: *font-line-height-xs
//   //     hoverColor: *color-brand-primary
//   // header:
//   //   height:
//   //     default: 80px
//   //     fixed: 50px;
//   //     homepage: 100px;
// }
