import * as React from 'react'

export interface MetricsContextIF {
  // width: number
  // height: number
  windowSizeKey: number
}
export const {
  Provider: MetricsProvider,
  Consumer: MetricsConsumer,
} = React.createContext({} as MetricsContextIF)
